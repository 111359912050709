import React, { useState, useEffect } from 'react';
import { Storage } from '@aws-amplify/storage';
import { toast } from 'react-hot-toast';

interface AsyncImageProps {
  src?: string;
  fallbackSrc: string;
  className?: string;
  alt?: string;
}

const AsyncImage: React.FC<AsyncImageProps> = ({
  src,
  fallbackSrc,
  className,
  alt,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(fallbackSrc);

  useEffect(() => {
    const decodeImage = async () => {
      if (!src) {
        setImageSrc(fallbackSrc);
        return;
      }

      try {
        const data = await Storage.get(src, {
          download: true,
        });

        if (data.Body) {
          if (data.Body.type !== 'binary/octet-stream') {
            // Handle Blob data
            const reader = new FileReader();
            reader.readAsDataURL(data.Body);
            reader.onloadend = () => {
              const decodedImage = reader.result as string;
              setImageSrc(decodedImage);
            };
          } else {
            // Handle text data
            const decodedImage = await data.Body.text();
            setImageSrc(decodedImage);
          }
        }
      } catch (error) {
        console.error('Error decoding image:', error);
        toast.error('Error decoding image');
        setImageSrc(fallbackSrc);
      }
    };

    decodeImage();
  }, [src, fallbackSrc]);

  return (
    <img
      className={className}
      src={imageSrc}
      alt={alt || 'Image'}
      onError={() => setImageSrc(fallbackSrc)}
    />
  );
};

export default AsyncImage;
